import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Carousel from '../../../components/Carousel/Carousel';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import Layout from '../../../Layout';
import { Button } from '../../../Molecules/Button/Button';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';

let classNames = require('classnames');

const StrategieCarboneBiodiversite = (pageContext) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "slider-image.png" }) {
        childImageSharp {
          mobile: fixed(width: 456, height: 456) {
            ...GatsbyImageSharpFixed
          }
          desktop: fixed(width: 1256, height: 814) {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { locale } = pageContext;

  const slider_settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '100px',
    slidesToShow: 1.5,
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1.05,
        dots: false
      }
    }]
  };

  const slides = [
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
      },
    },
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
      },
    },
    {
      image: data.file,
      info: {
        text: 'Nouveau site alimenté par la solution Wattway Pack',
      },
    },
  ];

  slides.push(...slides);

  const leftRightBlocks = [
    {
      text: '<p>Le changement climatique <strong>impacte</strong> directement Colas sur ses marchés, <strong>réduisant</strong> ainsi la part de certaines activités traditionnelles et ouvrant de nouvelles <strong>opportunités business.</strong></p><p>Une analyse des risques et opportunités du changement climatique pour l’ensemble du Groupe a été menée afin d’intégrer ces premiers éléments à la stratégie et à la feuille de route Business Développement.</p><p><strong>La sensibilisation de tous les collaborateurs,</strong> grâce à des concours, des webinaires et des journées consacrées à l’environnement, est donc nécessaire pour accompagner cette transition stratégique.</p>',
      title:
        'Intégrer les enjeux du changement climatique dans notre stratégie Groupe',
      visuel: data.file.childImageSharp
    },
    {
      text: '<p>L’un des objectifs de Colas est de <strong>réduire de 30% nos émissions directes de CO2</strong> d’ici 2030. Pour l’atteindre, nous travaillons pour l’ensemble des entités sur la <strong>réduction des consommations</strong> (réduction de la consommation énergétique des centrales d’enrobage grâce à l’excellence opérationnelle), <strong>l’efficacité énergétique</strong>, la transition vers des <strong>énergies moins carbonées</strong> (transition vers un flotte bas carbone), innover dans de nouveaux <strong>procédés de fabrication moins carbonés</strong> (carburants alternatifs, développement d’innovations pour substituer au fioul lourd) et <strong>accompagner le changement de comportement.</strong></p>',
      title:
        'Mettre en place des actions pour réduire l’intensité carbone de nos émissions directes',
      visuel: data.file.childImageSharp
    },
    {
      text: '<p>La réduction de notre impact carbone passera surtout par la mise en place de <strong>produits et techniques bas carbone</strong> développés et caractérisés par la R&D. Comme l’augmentation de l’utilisation des matériaux recyclés (carburants alternatifs, liants et fluxants biosourcés, …)</p><p>Cette réduction se concrétisera par le déploiement de ces produits et techniques via des <strong>actions commerciales</strong> et des propositions aux clients, notamment grâce à des <strong>éco-variantes.</strong></p>',
      title: 'Développer et promouvoir les techniques et solutions bas carbone',
      visuel: data.file.childImageSharp
    },
    {
      text: '<p>La mise en place d’une <p>comptabilité carbone</p> basée sur des flux physiques est en cours d’élaboration au niveau du Groupe, ainsi que le développement d’outils et de méthodologies de calcul <p>d’empreinte carbone</p> de nos chantiers. Cela permettra ensuite de mesurer à l’avancement l’impact carbone de chaque chantier.</p>',
      title: 'Optimiser la comptabilité carbone des activités',
      visuel: data.file.childImageSharp
    },
    {
      text: '<p>Des opportunités business sont à étudier autour de la <strong>captation et du stockage du CO2</strong>, de la lutte contre les <strong>îlots de chaleur urbains</strong>, de la mobilité et du <strong>transport décarboné…</strong></p><p>En effet, Colas souhaite intégrer dans ses activités de R&D les enjeux du réchauffement climatiques. Pour cela, grâce à des revêtements clairs, des dispositif liés au traitement de l’eau ou encore la végétalisation, nous enrichissons les solutions existantes de la lutte contre le ICU.<p></p>Colas promeut également le transport alternatif par rail ou voie navigable et met aussi en place des nouvelles solutions de mobilité : Qievo, Flowell…</p>',
      title:
        'Contribuer à la neutralité carbone et réduire les émissions de nos clients / usagers',
      visuel: data.file.childImageSharp
    },
    {
      text: '<p>Aujourd’hui, nous sommes témoin de la destruction continue de la nature et par conséquent, l’impulsion pour un monde différent a commencé. Le changement climatique étant l’une des principales causes, l’enjeu de cet axe pour Colas est de contribuer à préserver la biodiversité par ses activités, pas uniquement au niveau des carrières mais bien sur son ensemble grâce à des actions ciblées :</p><br><br><ul><li>Être acteur de la préservation des milieux et espèces : 100% de nos carrières ont une action en faveur de la biodiversité</li><li>Lancement d’un programme de sensibilisation et d’information</li><li>Maitrise des pollutions sur l’eau, les sols et l’air</li><li>Maitrise de la propagation des espèces exotiques envahissantes</li><li>Réponse aux enjeux de l’artificialisation des sols</li></ul>',
      title:
        'Intégrer dans les activités les enjeux liés à l’effondrement de la biodiversité',
      visuel: data.file.childImageSharp
    },
  ];

  return (
    <Layout>
      <Seo
        title="Colas Engagement Strategie Carbone Biodiversite"
        description="Strategie carbone et biodiversite"
      />
      <div
        className={classNames(
          'page_template',
          'zero_bottom_padding',
          'page_engagement_nos_preuves_biodiversite'
        )}
      >
        <ScrollPageNav
          data={[
            {
              title: 'Introduction',
              section: 'block_0_section'
            },
            {
              title: 'hide',
              section: 'block_1_section'
            },
            {
              title: 'Nos réalisations',
              section: 'block_2_section'
            },
            {
              title: 'Chiffres clés',
              section: 'block_3_section'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: { title: 'engagement', url: '/engagements' },
                currentPage: {
                  title: 'Strategie Carbone Et Biodiversite',
                  url: '/biodiversite',
                },
              }}
            />
            <TitlePage
              color="color_white"
              title="STRATEGIE CARBONE ET BIODIVERSITE"
            />
          </div>
        </PageBanner>
        <IntroPage
          customClass="block_0_section"
          title=""
          subtitle=""
          visuel={data.file.childImageSharp}
          text="<p>Le défi climatique représente un challenge inédit qui engage tous les acteurs de la mobilité. À notre échelle, Colas a un rôle important à jouer en alignant nos activités sur des objectifs de réduction de leur empreinte carbone et de sauvegarde de la biodiversité.</p>"
          size="border" //wrapper
        />
        <section className={classNames('section_content', 'block_1_section')}>
          <div className="wrapper_page_xs">
            {leftRightBlocks.map((block, index) => (
              <div key={index}>
                <TextVisuelTwoCol
                  title={block.title}
                  orderInverse={(index % 2 == 0) ? true : false}
                  visuel={block.visuel}
                  extactHalf={(index % 2 == 0) ? false : true}
                  alignLeft={(index % 2 == 0) ? true : false}
                  alignTop={true}
                  text={block.text}
                  textFullWidth={(index % 2 == 0) ? false : true}
                  smallVisuel={(index % 2 == 0) ? false : true}
                  extremeRight={(index % 2 == 0) ? true : false}
                  visuelAspectRatioMobile={1.34241245}
                  visuelAspectRatioDesktop={(index % 2 == 0) ? 1.04107143 : 0.699801193}
                  visuelMaxWidthDesktop={(index % 2 == 0) ? '100%' : 352}
                />
              </div>
            ))}
          </div>
        </section>
        <section className={classNames('section_content', 'block_2_section')}>
          <ShapeBackground
            top="left"
            color="catskill_white"
            bottomInfinite={true}
          >
            <div className={classNames('wrapper_page_xs')}>
              <TitleSection
                title="Nos réalisations"
                subtitle="Les actualités du groupe Colas, à travers le monde"
                type="arrow"
              />
            </div>
            <div className={classNames('wrapper_page_xs', 'only_desktop')}>
              <Carousel slides={slides} />
            </div>

            <div className={classNames('wrapper_page', 'only_mobile_tablet')}>
              <SliderComponent settings={slider_settings}>
                {slides.map((slide, i) => (
                  <div key={i}>
                    <div className="only_mobile">
                      {slide?.image &&
                        <Img
                          style={{ width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          fluid={{ ...slide.image.childImageSharp.mobile, aspectRatio: 0.733333333 }}
                        />
                      }
                    </div>
                    <div className="only_tablet">
                      {slide?.image &&
                        <Img
                          style={{ height: '100%', width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          fluid={{ ...slide.image.childImageSharp.desktop, aspectRatio: 1 }}
                        />
                      }
                    </div>
                    <div className="info_bleu_box">
                      <p>{slide.info.text}</p>
                      {slide.info.link && (
                        <Button
                          primary={true}
                          label={slide.info.link_label}
                          link={slide.info.link}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </SliderComponent>
            </div>
          </ShapeBackground>
        </section>
        <KeyNumbers
          title="Chiffre Clés"
          textColor="white"
          sectionName="block_3_section"
          NoPadding={true}
          locale={locale}
          datas={[
            {
              number: '33%',
              title: '<p>des engins équipés de télématique embarquée</p>',
            },
            {
              number: '9.803',
              title: '<p>millions de m² de chaussées recyclés en place</p>',
            },
            {
              number: '19%',
              title:
                '<p>d\'enrobés tièdes dans la production total d\'enrobés</p>',
            },
            {
              number: '56',
              title: '<p>sites acceuillants des ruches</p>',
            },
            {
              number: '145',
              title:
                '<p>espèces remarquables sont hébergées dans des carrières ou gravières</p>',
            },
            {
              number: '44%',
              title:
                '<p>du ca des carrières et gravières réalisent des actions en faveur de la biodiversité</p>',
            },
          ]}
        />
      </div>
    </Layout>
  );
};

export default StrategieCarboneBiodiversite;
